<template>
    <div class="top-25-index">
        <h1 class="top-25-index__title">{{ titleH1 }}</h1>
        <section class="top-25-index__section">
            <ul class="space-y-3">
                <li v-for="(item, key) in info.page.data.discounts" :key="item.title + item.id">
                    <DelayHydration>
                        <DCardAdmin v-if="item.is_admin" :info="item" />
                        <DCardMain v-else :info="item" />
                    </DelayHydration>
                </li>
            </ul>
        </section>
    </div>
</template>

<script lang="ts" setup>
import type { Api } from '~~/global'
import { replacePlaceholders } from '~~/util/textFunction'

const { $lang } = useNuxtApp()
const { currentDate } = useDateFunctions()

const { buildHeaders, baseUrl, endpoints } = useApiClient()

const { data: responseData, error } = await useAsyncData('top-25', () => {
    return $fetch<Api.Responses.Pages.top25>(endpoints.pages.top25.path, {
        headers: buildHeaders(),
        baseURL: baseUrl,
        method: 'GET',
    }).catch((e) => e.data)
})

if (error.value || !responseData.value || responseData.value.error) {
    throw createError({
        statusCode: responseData.value?.feedback === 'resource_not_found' ? 404 : 500,
        message:
            responseData.value?.feedback === 'resource_not_found' ? 'Página no encontrada' : 'Algo salió mal',
    })
}

let info = responseData.value as Api.Responses.Pages.top25

const currentDates = currentDate()
const {
    public: {
        variant: {
            metaData: { siteOrigin, siteName },
        },
    },
} = useRuntimeConfig()

const [titleH1, title, description] = replacePlaceholders(
    [
        [':month', currentDates.month || ''],
        [':day', String(currentDates.day) || ''],
        [':year', String(currentDates.year) || ''],
        [':site', siteName || ''],
    ],
    [
        info.page.h1 || 'Top 25',
        info.page.title || 'Top 25 descuentos | :site',
        info.page.description ||
            'Los mejores 25 cupones y ofertas del día. Encuentra los top códigos descuento y promociones.',
    ],
)

const Route = useRoute()

useSeoMeta({
    title: title,
    ogUrl: siteOrigin + Route.path,
    ogTitle: title,
    description: description,
    ogDescription: description,
    ogImageAlt: title,
    twitterCard: 'summary_large_image',
})

useHead({
    link: [
        {
            rel: 'canonical',
            href: siteOrigin + Route.path,
        },
    ],
})
</script>

<style lang="postcss">
.top-25-index {
    @apply container;
    &__title {
        @apply my-3 text-center text-[28px] md:text-[32px];
    }
    &__section {
        @apply mx-auto mb-5 max-w-5xl;
    }
}
</style>
